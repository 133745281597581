import { Storage } from '@capacitor/storage';

export default {
  async getMember() {
    const user = await Storage.get({ key: 'user' });
    return user.value?JSON.parse(user.value):[];
  },
  async getUserId() {
    const user = await Storage.get({ key: 'user' });

    if (!user.value) return 0;

    const { userId } = JSON.parse(user.value);

    return userId || 0;
  },
  async getMemberType() {
    const user = await Storage.get({ key: 'user' });
    console.log(user);

    if (!user.value) return 0;

    const { memberType } = JSON.parse(user.value);

    return memberType || 0;
  },
  async getUsername() {
    const user = await Storage.get({ key: 'user' });

    if (!user.value) return '';

    const { username } = JSON.parse(user.value);

    return username || '';
  },
  async getMerchantId() {
    const user = await Storage.get({ key: 'user' });

    if (!user.value) return 0;

    const { merchant_id } = JSON.parse(user.value);

    return merchant_id || 0;
  },
  async getLastMerchantId() {
    const user = await Storage.get({ key: 'user' });

    if (!user.value) return 0;

    const { lastMerchant_id } = JSON.parse(user.value);

    return lastMerchant_id || 0;
  },
};
