import api from '../api';
import { Storage } from '@capacitor/storage';
import UserTypes from '../enums/UserTypes';
import useToast from './useToast';

export default function () {
  const { openToast } = useToast();

  async function getUserRole() {
    const token = await Storage.get({ key: 'token' });
    const user = await Storage.get({ key: 'user' });
    const merchant = await Storage.get({ key: 'staff_merchant_id' });
    var form = new FormData();
    form.append("token", token.value);
    form.append("member_id", JSON.parse(user.value).userId);
    if (merchant.value) {
      form.append("merchant_id", merchant.value);
    }
    const response = await api.post('/roles', form);
    if (response.status == 200) {
      const data = response.data;
      if (data.status == 0) {
        await Storage.set({ key: 'roles', value: JSON.stringify(data.data.roles)});
        return data.data.roles;
      }
    }
    console.log("role.js getUserRole ok but no 200", response);
    return "";
  }
  
  async function checkPage(pathname) {
    const token = await Storage.get({ key: 'token' });
    const user = await Storage.get({ key: 'user' });
    const roles = await Storage.get({ key: 'roles' });
    if (!token.value || !user.value) {
      // console.log("role.js checkPage false 1", token.value, user.value);
      openToast("You don't have permission to do that", 'warning', 'top');
      return false;
    }
    let rolelist = false;
    if (!roles.value) {
      rolelist = await getUserRole();
    } else {
      rolelist = JSON.parse(roles.value);
    }

    if (rolelist[pathname] == undefined) { // Not premission control on this pathname
      return true;
    }
    // 0: no access, 1: with access
    if (rolelist[pathname] > 0) {
      return true;
    }
    // console.log("role.js checkPage false 3", rolelist[pathname]);
    openToast("You don't have permission to do that", 'warning', 'top');
    return false;
  }

  async function checkAdmin(pathname) {
    const user = await Storage.get({ key: 'user' });

    if (user && user.value) {
      const member = JSON.parse(user.value);
      if (member.memberType == UserTypes.ADMIN) {
        return true;
      }
    }

    // console.log("role.js checkAdmin false", pathname);
    return false;
  }

  async function getMerchantRoleGroupList() {
    const token = await Storage.get({ key: 'token' });
    const merchant = await Storage.get({ key: 'staff_merchant_id' });
    var form = new FormData();
    form.append("token", token.value);
    if (merchant.value) {
      form.append("merchant_id", merchant.value);
    }
    const response = await api.post('/getMerchantRoleGroupList', form);
    if (response.status == 200) {
      return response.data;
    }
    console.log("role.js getMerchantRoleGroupList ok but no 200", response);
    return "";
  }
  
  /**
   * get rolegroup permission in permission tree for display
   * @param {*} roleGroupId if 0, get permission tree only
   * @returns 
   */
  async function getRoleGroupById(roleGroupId) {
    const token = await Storage.get({ key: 'token' });
    var form = new FormData();
    form.append("token", token.value);
    form.append("roleGroupId", roleGroupId);
    const response = await api.post('/getRoleGroupById', form);
    if (response.status == 200) {
      return response.data;
    }
    console.log("role.js getRoleGroupById ok but no 200", response);
    return "";
  }
  
  async function updateRoleGroup(roleGroup) {
    const token = await Storage.get({ key: 'token' });
    const merchant = await Storage.get({ key: 'staff_merchant_id' });
    if (merchant.value) {
      roleGroup.merchant_id = merchant.value;
    }
    var form = new FormData();
    form.append("token", token.value);
    form.append("roleGroup", JSON.stringify(roleGroup));
    const response = await api.post('/updateRoleGroup', form);
    if (response.status == 200) {
      return response.data;
    }
    console.log("role.js updateRoleGroup ok but no 200", response);
    return "";
  }

  return {
    checkPage,
    checkAdmin,
    getMerchantRoleGroupList,
    getRoleGroupById,
    updateRoleGroup,
    // getEdit,
  };
}
