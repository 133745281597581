import UserTypes from '../../enums/UserTypes';
import role from '../../composition/role';

export default [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('../../pages/admin/Dashboard.vue'),
    meta: { memberType: UserTypes.ADMIN },
  },
  {
    path: '/admin/coupons',
    name: 'admin-coupons',
    component: () => import('../../pages/admin/Coupons.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-coupons').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/coupon/:id',
    name: 'admin-coupon',
    component: () => import('../../pages/admin/CouponForm.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-coupon').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/groups',
    name: 'admin-groups',
    component: () => import('../../pages/admin/Groups.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-groups').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/group/:id',
    name: 'admin-group-merchant',
    component: () => import('../../pages/admin/GroupForm.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-group-merchant').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/tags',
    name: 'admin-tags',
    component: () => import('../../pages/admin/Tags.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-tags').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/tagedit/:id',
    name: 'admin-tagedit',
    component: () => import('../../pages/admin/TagEdit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-tagedit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/advertisings',
    name: 'admin-advertisings',
    component: () => import('../../pages/admin/Advertisings.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-advertisings').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/advertising/:id',
    name: 'admin-adform',
    component: () => import('../../pages/admin/AdForm.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-adform').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/vip-cards',
    name: 'admin-vip-cards',
    component: () => import('../../pages/admin/VipCards.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-vip-cards').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/banners/:id',
    name: 'admin-banners',
    component: () => import('../../pages/admin/Banners.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-banner').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/banner-edit/:id/:merchantid',
    name: 'admin-banner-edit',
    component: () => import('../../pages/admin/Banner_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-banner-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/banner-preview',
    name: 'admin-banner-preview',
    component: () => import('../../pages/admin/Banner_preview.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-banner-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/vip-card-search/:id',
    name: 'admin-vip-card-search',
    component: () => import('../../pages/admin/VipCardSearch.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-vip-card-search').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/vip-card-form/:id/:merchantid',
    name: 'admin-vip-card-form',
    component: () => import('../../pages/admin/VipCardForm.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-vip-card-form').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/import-vip-card/:id',
    name: 'admin-import-vip-card',
    component: () => import('../../pages/admin/ImportVipCard.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-import-vip-card').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/members',
    name: 'admin-members',
    component: () => import('../../pages/admin/Members.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-members').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/add-member',
    name: 'admin-add-member',
    component: () => import('../../pages/admin/AddMember.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-add-member').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/edit-member/:id',
    name: 'admin-edit-member',
    component: () => import('../../pages/admin/EditMember.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-edit-member').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/merchants',
    name: 'admin-merchants',
    component: () => import('../../pages/admin/Merchants.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-merchants').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/edit-merchant/:id',
    name: 'admin-edit-merchant',
    component: () => import('../../pages/admin/EditMerchant.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-edit-merchant').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/add-merchant',
    name: 'admin-add-merchant',
    component: () => import('../../pages/admin/EditMerchant.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-add-merchant').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/scan',
    name: 'admin-scan',
    component: () => import('../../pages/admin/Scan.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-scan').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/membercard/:cardId/:cardNo',
    name: 'admin-member-card',
    component: () => import('../../pages/admin/MemberCard.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-member-card').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/success',
    name: 'admin-success',
    component: () => import('../../pages/admin/Success.vue'),
  },
  {
    path: '/admin/fail',
    name: 'admin-fail',
    component: () => import('../../pages/admin/Fail.vue'),
  },
  {
    path: '/admin/activity/:memberCardId',
    name: 'admin-points-activity',
    component: () => import('../../pages/admin/PointsActivity.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-points-activity').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/membercouponlist/:memberId/:merchantId',
    name: 'admin-member-coupon-list',
    component: () => import('../../pages/admin/MemberCouponList.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-member-coupon-list').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/activatecoupon/:couponId/:number',
    name: 'admin-activate-coupon',
    component: () => import('../../pages/admin/CouponActivation.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-activate-coupon').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/stafflist',
    name: 'admin-staff-list',
    component: () => import('../../pages/admin/StaffList.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-staff-list').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/staffadd',
    name: 'admin-staff-add',
    component: () => import('../../pages/admin/Staff_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-staff-add').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/staffedit/:data',
    name: 'admin-staff-edit',
    component: () => import('../../pages/admin/Staff_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-staff-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/staffdetail/:data',
    name: 'admin-staff-detail',
    component: () => import('../../pages/admin/Staff_detail.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-staff-detail').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/rolelist',
    name: 'admin-role-list',
    component: () => import('../../pages/admin/RoleList.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-role-list').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/roleadd',
    name: 'admin-role-add',
    component: () => import('../../pages/admin/Role_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-role-add').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/roleedit/:id',
    name: 'admin-role-edit',
    component: () => import('../../pages/admin/Role_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-role-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/merchantqrcode/:id',
    name: 'admin-merchant-qrcode',
    component: () => import('../../pages/admin/MerchantQRCode.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-merchant-qrcode').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/homepagesetting/:id',
    name: 'admin-home-page',
    component: () => import('../../pages/admin/HomePageSetting.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-home-page').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/couponlist',
    name: 'admin-coupon-list',
    component: () => import('../../pages/admin/CouponList.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-coupon-list').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/coupondetail/:id',
    name: 'admin-coupon-detail',
    component: () => import('../../pages/admin/CouponDetail.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-coupon-detail').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/couponcreate',
    name: 'admin-coupon-create',
    component: () => import('../../pages/admin/CouponCreate.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-coupon-create').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/couponedit/:id',
    name: 'admin-coupon-edit',
    component: () => import('../../pages/admin/CouponEdit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-coupon-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/mailchimp',
    name: 'admin-mailchimp',
    component: () => import('../../pages/admin/Mailchimp.vue'),
    beforeEnter: (to, from, next) => {
      const { checkAdmin } = role();
      checkAdmin('admin-mailchimp').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/sms',
    name: 'admin-sms',
    component: () => import('../../pages/admin/Sms.vue'),
    beforeEnter: (to, from, next) => {
      const { checkAdmin } = role();
      checkAdmin('admin-sms').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/smstemplatelist',
    name: 'admin-template',
    component: () => import('../../pages/admin/SmsTemplate.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-template').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/smstemplateadd/:id?',
    name: 'admin-smstemplate-add',
    component: () => import('../../pages/admin/SmsTemplate_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-smstemplate-add').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/smstemplateedit/:id',
    name: 'admin-smstemplate-edit',
    component: () => import('../../pages/admin/SmsTemplate_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-smstemplate-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/smstemplatereview/:id',
    name: 'admin-smstemplate-review',
    component: () => import('../../pages/admin/SmsTemplate_review.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-smstemplate-review').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/smstemplatesuccess',
    name: 'admin-smstemplate-success',
    component: () => import('../../pages/admin/SmsTemplate_success.vue'),
  },
  {
    path: '/admin/smsedit/:id?',
    name: 'admin-sms-edit',
    component: () => import('../../pages/admin/Sms_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-sms').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/mailchimpedit/:id?',
    name: 'admin-mailchimp-edit',
    component: () => import('../../pages/admin/Mailchimp_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-mailchimp').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/referal',
    name: 'admin-referal',
    component: () => import('../../pages/admin/Referal.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-referal').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/referaledit/:id?',
    name: 'admin-referal-edit',
    component: () => import('../../pages/admin/Referal_edit.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-referal-edit').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/referaldetail/:id',
    name: 'admin-referal-detail',
    component: () => import('../../pages/admin/Referal_detail.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-referal-detail').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/partnerreport/:id/:lid/:tm',
    name: 'admin-partner-report',
    component: () => import('../../pages/admin/Referal_partner_report.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-referal-detail').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/admin/partnerdownload/:id',
    name: 'admin-partner-download',
    component: () => import('../../pages/admin/Referal_partner_download.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('admin-referal-detail').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
];
