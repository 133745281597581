export default {
  '': '',
  ' ': ' ',
  "''": "''",
  ' format file.': '格式的檔案',
  ' segments. Extra charge may be applied.': '部分發送。 可能造成額外收費。 ',
  ' text messages)': '條短信）',
  "'s QR Code": " 推廣碼",
  'Account': '帳戶',
  'Account Info': '帳號資訊',
  'Activated': '已啟動',
  'Active': '啟動',
  'Add': '新增',
  'Add Merchant': '新增商家',
  'Add new Referral': '添加團長',
  'Add Partner': '添加團長',
  'Add Referal': '新增分销策略',
  'Add Role': '建立角色權限',
  'Add Staff': '建立員工帳號',
  'Add vip card successfully': '添加卡片成功',
  'Address': '地址',
  'AD Banner': '廣告橫幅',
  'AD Banner Visibility': '廣告橫幅顯示範圍',
  'AD Image is required': '請選擇廣告橫幅',
  'After Tax': '稅後',
  'Agree to': '我已閱讀並同意',
  'Agree to terms and conditions': '我已閱讀並同意《用戶服務協議》',
  'All': '全部',
  'All Coupons': '所有優惠券',
  'All Stores': '所有店鋪',
  'All Vip Cards': '所有會員卡',
  'Amount': '金額',
  'Amount1': '總提成',
  'Amount2': '提成',
  'An exception happened': '發生異常',
  'Api Key': 'API鑰匙',
  'Apply': '領取',
  'Are you sure to delete this banner?': '確定要刪除該廣告橫幅？ ',
  'Are you sure to delete this card?': '確定要刪除該卡片？ ',
  'Are you sure to delete this referal?': '確定要刪除此分銷策略嗎？',
  'Are you sure to delete this staff?': '確定要註銷這名員工的帳戶？ ',
  'Are you sure to delete this tag?': '確定要刪除這個標籤？ ',
  'Are you sure to disable this card?': '確定要停用這張卡？ ',
  'Are you sure to text all the members?': '你確定要啟用短信通知所有會員嗎？',
  'Available': '有效',
  'Available from': '可使用開始日期',
  'Back': '返回',
  'Balance': '餘額',
  'Before Tax': '稅前',
  'Bonus': '贈送',
  'By File': '導入檔案',
  'By Parameters': '指定參數',
  'By Time': '按時間',
  'Campaign name': '活動名稱',
  'Cancel': '取消',
  'Cancel Redeem ': '取消兌換',
  "Can't read qrcode": "無法讀取二維碼",
  'Cards': '卡片',
  'Card Details': '詳情',
  'Card Holder': '持卡人',
  'Card Image': '圖案',
  'Card Info': '會員卡詳情',
  'Card Name': '卡名',
  'Card Number': '卡號',
  'Card number is duplicated at line ': '卡號重複，所在行號：',
  'Card Number Length': '卡號長度',
  'Card Number Length is too small': '卡號長度太短',
  'Card Number Prefix': '卡號字首',
  "Card Number Prefix can't be longer than Card Number Length": "卡號字首不能比卡號長度更長",
  'Card Number Start From': '卡號起始',
  'Card Quantity': '生成卡片的數量',
  'Card Quantity is too small': '卡片數量太少',
  'Card Search part1': '找到',
  'Card Search part2': '個結果',
  'Card Type': '類別',
  'Cell Number': '手機號碼',
  'Chain Store': '連鎖店',
  'Chain Store List': '連鎖店列表',
  'Change Status': '更改狀態',
  'Chinese': '中文',
  'Chinese1': '默認中文',
  'Chinese Card Name': '中文卡名',
  'Chinese Coupon Title': '中文大標題',
  'Chinese Description': '中文描述',
  'Chinese Description1': '中文活動規則',
  'Chinese Description2': '中文活動內容',
  'Chinese description is required': '請提供中文活動內容',
  'Chinese Name': '中文名稱',
  'Chinese Point Rules': '中文積分法則',
  'Chinese Store Brief Introduction': '中文店家簡介',
  'Chinese Title': '中文活動大標題',
  'Chinese title is required': '請提供中文活動大標題',
  'Choose file to upload': '選擇檔案上傳',
  'Choose from Gallery': '從手機相簿新增',
  'Choose Language': '選擇語言',
  'Choose store': '選分店',
  'Choose template': '選擇模板',
  'Claimed': '已領取',
  'Claim Coupon': '去領取',
  'Claim Now': '立即領取',
  'Claim On Platform': '開放平台領取',
  'Close': '關閉',
  'Complete': '完成',
  'Confirm': '確認',
  'Contact Name': '聯絡人名稱',
  'Contact Us': '聯絡我們',
  'Copy Link': '複製連結',
  'Cost Per Message': '每條訊息成本',
  'Coupon': '優惠券',
  'Coupon can only be applied by members': '優惠券僅限會員領取',
  'Coupon Detail': '優惠券詳情',
  'Coupon Image': '優惠券圖片',
  'Coupon Management': '管理優惠券',
  'Coupon Numbers': '優惠券號碼',
  'Coupon Title': '大標題',
  'Coupon Total Counts': '優惠券總數',
  'Coupons available': '可用優惠券',
  'Create Campaign': '建立短訊範本',
  'Create Card': '建立卡片',
  'Create Coupon': '添加優惠券',
  'Create Date': '建立日期',
  'Create Event': '創建活動',
  'Created on': '創建日期',
  'Current Points': '當前積分',
  'Customer': '自訂',
  'Customize': '自定義',
  'Custom Reload': '自訂儲值',
  'Date of Birth': '出生日期',
  'Deduct': '抵扣',
  'Deduct Rate(Point to Cash Rate)': '積分抵扣現金的比例',
  'Default same as AD Banner': '默認廣告橫幅',
  'Delete': '刪除',
  'Delete Confirmation': '刪除提示',
  'Delete Staff': '註銷帳戶',
  'Description': '描述',
  'Design': '設計',
  'Detail': '詳細',
  'Detail Image': '內容配圖',
  'Disable': '禁用',
  'Disabled': '禁用',
  'Disabled2': '已暫停',
  'Disable successfully': '禁用成功',
  'Discount Amount ($)': '折扣金額 ($)',
  'Discount Percentage (%)': '折扣百分比 (%)',
  'Description': '描述',
  'Download': '下載圖片',
  'Download Member Analyses': '下載會員分析',
  'Download QR Code': '下載二維碼',
  'Do you agree to applied the free VIP member card': '您是否同意申請免費VIP會員卡',
  'Draft': '草稿',
  'Duplicate': '複製',
  'Edit': '修改',
  'Edit Coupon': '修改優惠券',
  'Edit Referal': '修改團長分銷',
  'Edit Role': '修改角色權限',
  'Edit Staff': '修改員工帳號',
  'Email': '電子郵件',
  'Enable SMS Notification': '啟用短信通知',
  'English': '英文',
  'English1': '默認英文',
  'English Card Name': '英文卡名',
  'English Coupon Title': '英文大標題',
  'English Description': '英文描述',
  'English Description1': '英文活動規則',
  'English Description2': '英文活動內容',
  'English description is required': '請提供英文活動內容',
  'English Name': '英文名稱',
  'English Point Rules': '英文積分法則',
  'English Store Brief Introduction': '英文店家簡介',
  'English Title': '英文活動大標題',
  'English title is required': '請提供英文活動大標題',
  'Enter Amount': '輸入金額',
  'Enter cell number': '輸入手機號碼',
  'Enter Cellphone Number': '輸入手機號',
  'Enter Code': '輸入驗證碼',
  'Enter Discount Amount': '輸入折扣金額',
  'Enter Discount Percentage': '輸入折扣百分比',
  'Enter Manually': '輸入號碼',
  'Enter password': '輸入密碼',
  'Enter phone number': '請輸入電話號碼',
  'Event Details': '活動詳情',
  'Exceed maximum limit': '超過最大長度限制',
  'Exclusive': '獨家的',
  'Existing member': '現有會員',
  'Expired': '已過期',
  'Expired Date:': '有效期限至：',
  'Expiry Date': '可使用結束日期',
  'Explore Merchants': '搜尋商家',
  'Fail': '失敗',
  'Female' : '女',
  'Finished': '完成',
  'Fixed Amount': '固定值',
  'For Sale': '出售',
  'Forgot password': '忘記密碼',
  'Free Apply': '免費領取',
  'From Gallery': '相簿',
  'From Phone Number': '發送人電話號碼',
  'Functions': '功能',
  'Full Permission': '所有權限',
  'Full Size': '全螢幕',
  'Gender': '性別',
  'Generate No.': '產生編號',
  'Gerenating': '生成中',
  'Get Verification code': '發送驗證碼',
  'Gift Card': '禮品卡',
  'Have an account already': '已有帳戶？ ',
  'Head Image': '頭部影像',
  'Header String': '標頭字串',
  'Headquarter Only': '僅限總店',
  'History1': '消費記錄',
  'Home': '首頁',
  'Home Page Setting': '首頁設置',
  'Home Top Picture': '首頁置頂背景圖',
  'How many cards to create': '建立多少張卡片',
  'How many characters card number contains': '卡號包含多少個字元',
  'Image': '圖片',
  'Image size cannot be over 2MB': '圖片大小不能超越2MB',
  'Import': '導入',
  'Import success': '導入成功',
  'In Progress': '進行中',
  'In Use': '使用中',
  'Inactive': '無效',
  'Increase issuance of coupons': '增發優惠券',
  'Increasing': '增發中',
  'Input phone No./card No./name': '輸入會員電話號碼/卡號/名字',
  'Input Amount': '輸入金額',
  'Input Bonus': '輸入贈送金額',
  'Input to Search': '輸入關鍵字',
  'Input URL': '輸入鏈接',
  'Insufficient points': '積分不足',
  'Invalid code': '無法辨識',
  'Invalid QR Code': '二維碼已失效',
  'Invoice Percentage Discount': '全單百分比折扣',
  'Invoice Price Discount': '全單價格折扣',
  'Issue Start Date': '發行開始日期',
  'Issue End Date': '發行結束日期',
  'Issued': '已發行',
  'Issuing': '發行中',
  'Item Percentage Discount': '單品百分比折扣',
  'Item Price Discount': '單品價格折扣',
  'Last Month': '上月',
  'Last Updated:': '截止時間:',
  'Last 6 Months': '過去6個月',
  'Layout': '佈局',
  'Less': '縮小',
  'Less than 20 characters': '少於20字元',
  'Link Copied': '連結已複製',
  'List Id': '列表Id',
  'Load': '載入',
  'Loading': '載入中',
  'Location': '位置',
  'Login': '登錄',
  'Logout': '登出',
  'Logo and Name': '同時顯示商家名稱 + 標誌圖',
  'Make sure you entered correct phone number': '确保輸入正確電話號碼',
  'Male' : '男',
  'Main Merchant': '主店商',
  'Manage Banners': '運營配置',
  'Manage Coupons': '優惠券管理',
  'Manage Member': '會員管理',
  'Manage Merchant': '店商管理',
  'Manage Referal': '團長分銷',
  'Manage Role': '角色管理',
  'Manage SMS': 'SMS管理',
  'Manage Staff': '員工管理',
  'Master Merchant': '主店',
  'Maximum 20 characters': '輸入最多20字',
  'Maximum 150 characters': '輸入最多150字',
  'Maximum 500 characters': '輸入最多500字',
  'Maximum characters reached': '已達到最大字符限制',
  'Max Quantity': '最大數量',
  'Member': '目前會員',
  'Member Discount': '會員優惠',
  'Member Info': '會員詳情',
  'Merchant': '商家',
  'Merchant Group': '店商組',
  'Merchant ID': '商家ID',
  'Merchant Info': '商家資訊',
  'Merchant List': '商家列表',
  'Merchant Login': '商家登錄',
  'Merchant Token': '商家代幣',
  'Message': '簡訊',
  'Mine': '我的',
  'Modify Account Info': '更改帳戶資訊',
  'Modify Card': '修改卡片',
  'Modify Event': '修改活動',
  'Modify Merchant Info': '修改商家資訊',
  'Modify Password': '更改密碼',
  'More...': '更多...',
  'More Promotions': '更多優惠',
  'My VIP Cards': '我的會員卡',
  'Name': '名稱',
  'Name1': '團長',
  'New Password': '新密碼',
  'New user': '新用戶',
  'No': '否',
  'No Content': '沒有內容',
  'No Coupon': '沒有任何優惠券',
  'No Data': '無數據',
  'No data created': '沒有可產生的資料',
  'No data in excel file': 'Excel檔案中沒有資料',
  'No Import Data': '沒有導入資料',
  'No Logo, No Name': '不顯示商家名稱 + 標誌圖',
  'No merchant is selected': '沒有選擇商家',
  'No Vip Card': '沒有任何會員卡',
  'Notes': '註釋',
  'N/A': '無',
  'Offline': '下線',
  'Offset': '核銷',
  'Old Password': '舊密碼',
  'Online': '上線',
  'Online Order Function': '線上點單功能',
  'Online order link is required': '請提供線上點單鏈接',
  'Only For Referrer': '僅限團長分銷',
  'Only Logo': '只顯示商家標誌圖',
  'Only Name': '只顯示商家名稱',
  'OK': '確定',
  'Order': '排序',
  'Others': '其他',
  'Partial Permission': '部分權限',
  'Partners': '團長管理',
  'Partner QR Code': '團長推廣碼',
  'Partner Report': '分銷成績詳情',
  'Password': '密碼',
  'Pause': '暫停發行',
  'Pause Coupon Distribution': '暫停發行提示',
  'Pause Coupon Distribution DESC': "暫停發行此優惠卷，用戶將無法在商家首頁看見，已領取的優惠卷仍可使用。",
  'Paused': '停用中',
  'Pay Amount': '消費金額',
  'Pay Cancel': '消費取消',
  'Pending': '待發送',
  'Percentage': '百分比',
  'Permission Includes': '權限包含',
  'Persional Info': '登記個人資料',
  'Personal Information': '個人資訊',
  'Phone': '電話',
  'Phone number': '電話號碼',
  'Phone number cannot be empty': '電話號碼不能為空',
  'Phone number invalid': '電話號碼格式錯誤',
  'Please check SMS on your phone with the number': '请打开您電話號碼的手机查看短信',
  'Please choose a date': '請選擇日期',
  'Please enter a description': '請輸入描述',
  'Please enter a number': '請輸入號碼',
  'Please enter a role name': '請輸入角色名稱',
  'Please Input Quantity': '請輸入數量',
  'Please Input Verify Code': '請輸入驗證碼',
  'Please provide a title': '請輸入大標題',
  'Please read and agree to the terms and conditions': '請閱讀並同意《用戶服務協議》',
  'Please scan membership QR Code OR Coupon QR Code': '請掃描客戶會員卡二維碼 或 優惠券二維碼',
  'Please select a type': '請選擇類別',
  'Please upload ': '請上傳',
  'Please upload an image': '請上傳圖片',
  'Points': '積分',
  'Points Earned': '賺取積分',
  'Points Used': '使用積分',
  'Points History': '積分記錄',
  'Points to earn : ': '積分取得： ',
  'Points used': '積分使用',
  'Point Rate(Total to Point Rate)': '消費金額兌換積分的倍率',
  'Point Rules': '積分規則',
  'Preset Reload and Bonus': '預設儲值與贈送',
  'Preset part1': '充',
  'Preset part2': '送',
  'Preset part3': '',
  'Preview': '預覽',
  'Preview SMS': '短信預覽',
  'Product': '產品',
  'Quantity cannot be empty': '數量不能為空',
  'Quantity can not larger than 2000': '數量不能大於2000',
  'Quantity is invalid': '數量不合法',
  'Quantity must be positive': '數量必須為正數',
  'Recall': '撤回',
  'Recommanded-ADBanner': '建議解析度為311*112，檔案大小小於2MB，保持視覺元素居中 ',
  'Recommanded-CardPic': '建議解析度為343*217，檔案大小小於2MB，保持視覺元素居中',
  'Recommanded-CouponImage': '建議解析度為343*145，檔案大小小於2MB，保持視覺元素居中',
  'Recommanded-DetailImage': '建議圖片寬度為343像素，檔案大小小於2MB，保持視覺元素居中',
  'Recommanded-HomeTopPic': '建議解析度為375*265，檔案大小小於2MB，保持視覺元素居中',
  'Recommanded-ReferalImage': '建議解析度為343*260，檔案大小小於2MB，保持視覺元素居中',
  'Recommanded-Splash': '建議解析度為375*812，檔案大小小於2MB，保持視覺元素居中',
  'Recommanded-TopPic': '建議解析度為343*183，檔案大小小於2MB，保持視覺元素居中',
  'Redeem': '兌換',
  'Redeem ': '兌換',
  'Redeem Rate(Point to Cash Rate)': '積分兌換現金的比例',
  'Referrals': '推薦人數',
  'Referal Detail': '團長分銷詳情',
  'Register': '註冊帳戶',
  'Reimburse Method': '團長提成方式',
  'Reload': '儲值',
  'Reload Amount': '充值金額',
  'Reload Cancel': '充值取消',
  'Resendpart1': '',
  'Resendpart2': '秒後可重新發送',
  'Resendpart3': '收不到驗證碼？ ',
  'Resendpart4': '重新發送',
  'Resend': '重新發送',
  'Resend Code in': '重新發送',
  'Reset Password': '重設密碼',
  'Results': '分銷成績',
  'Resume': '重新發行',
  'Resume Coupon Distribution': '重新發行提示',
  'Resume Coupon Distribution DESC': "重新發行此優惠卷，用戶將可在商家首頁看見並可使用。",
  'Role': '角色',
  'Role Name': '角色名稱',
  'Rules': '活動規則',
  'Save': '保存',
  'Scan to Redeem': '掃碼領券',
  'Scan QR Code': '掃一掃',
  'Scan your card here': '掃描你的會員卡',
  'Schedule for Later': '稍後發送',
  'Scheduled': '稍後發送',
  'Search': '搜尋',
  'Search Store and Coupons': '搜尋商店和優惠券',
  'seconds': '秒',
  'Select': '選擇',
  'Select a Product': '指定單品',
  'Select Coupon': '選擇優惠券',
  'Select Date': '選擇日期',
  'Select file(xlsx) to import': '請導入xlsx檔',
  'Selected Stores': '已選分店',
  'Select Spread Type': '選擇推廣方式',
  'Select Type': '選擇類別',
  'Self': '自己',
  'Send': '發送',
  'Sending': '發送中',
  'Send Now': '馬上發送',
  'Send time must be at least 1 hour from now': '發送時間必須至少從現在起1小時後',
  'Sent': '已發送',
  'Sent successfully': '發送成功',
  'Sent with Success': '成功發送',
  'Set URL': '配置鏈接（URL）',
  'Setup Password': '設定密碼',
  'Sign In': '登錄',
  'SMS Notification': '短信提示',
  'SMS Preview': '短信預覽',
  'Sold': '售出',
  'Something Worng, Please try it later.': '出錯，請稍後再試',
  'Seconds': '秒',
  'SMS Language': '短信文案配置',
  'SMS Management': 'SMS管理',
  'SMS Notification Cannot Be Enabled': '無法啟用短信通知',
  'Spend & Reload': '消費及充值記錄',
  'Splash Page': '閃屏頁面',
  'Spread Type': '推廣方式',
  'Staff Info': '員工詳情',
  'StaffName': '姓名',
  'Status': '狀態',
  'Store': '商家',
  'Store Brief Introduction': '店鋪簡介',
  'Store Detail': '商家詳情',
  'Store QRCode': '店舖二維碼',
  'Submit': '提交',
  'Submit Request': '提交請求',
  'Success': '成功',
  'System': '系統',
  'Tag': '標籤',
  'Tag List': '標籤列表',
  'Take photo': '拍照',
  'Task in progress': '任務進行中',
  'terms and conditions': '《用戶服務協議》',
  'Text message': '簡訊內容',
  'Theme Color': '主題色',
  'The "Only For Referrer" coupon does not support SMS notification to users.': '「僅限團長分銷」的優惠卷不支持短信通知用戶功能。',
  'This card is not in use': '此卡未在使用中',
  'This is not your store card': '這不是您店裡的卡',
  'This is not your store coupon': '這不是您店裡的優惠券',
  'This message will be sent as ': '此簡訊將分為',
  'This Month': '本月',
  'This Year': '本年',
  'Time Zone': '時區',
  'Title': '大標題',
  'Token': '驗證代幣',
  'Top Image': '置頂宣傳圖',
  'Top Picture': '置頂圖',
  'Total Amount': '消費總金額',
  'Total Cost': '總計費用',
  'Total number should be no more than 2000': '總數不能超過2000',
  'Total Sent': '簡訊總數',
  'Total Spent': '消費總金額',
  'Transaction Records': '交易記錄',
  'Try Again': '重試',
  'Type': '類別',
  'Type your SMS content here...': '請輸入SMS內容...',
  'Unable to send': '無法發送',
  'Unknown': '未知',
  'Unknown Parameter': '未知參數',
  'Unknown Merchant': '未知商家',
  'Update status successfully': '更新狀態成功',
  'Update vip card successfully': '更新卡片成功',
  'Upload': '上傳',
  'Upload picture': '上傳照片',
  'Used': '已使用',
  'Use Manually': '手動使用',
  'Use points': '使用積分',
  'Valid Until': '有效期限至',
  'Valid Until ': '有效日期至',
  'Verification Code': '輸入驗證碼',
  'Verification Code is sended to': '驗證碼已發送到',
  'Verify': '驗證',
  'Verify Error': '驗證錯誤',
  'VIP Card': '會員卡',
  'VIP Card Management': '會員卡管理',
  'Welcome': '歡迎',
  'Wallet': '卡包',
  'What the card number start with': '卡號以什麼開頭',
  'Which number to start numbering': '從哪個數字開始編號',
  'Year to Date': '本年迄今',
  'Yes': '是',
  '(Totally send ': '（預計將發送',
  // more translation keys...
}
